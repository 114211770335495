define('ember-router-scroll/index', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var next = _ember['default'].run.next;
  exports['default'] = _ember['default'].Mixin.create({
    service: inject.service('router-scroll'),

    isFastBoot: computed(function () {
      var fastboot = getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),

    willTransition: function willTransition() {
      this._super.apply(this, arguments);
      get(this, 'service').update();
    },

    didTransition: function didTransition(transitions) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [transitions].concat(args));

      if (get(this, 'isFastBoot')) {
        return;
      }

      next(function () {
        return _this.updateScrollPosition(transitions);
      });
    },

    updateScrollPosition: function updateScrollPosition(transitions) {
      var scrollPosition = get(this, 'service.position');

      var preserveScrollPosition = transitions[transitions.length - 1].handler.controller.get('preserveScrollPosition');

      if (!preserveScrollPosition) {
        window.scrollTo(scrollPosition.x, scrollPosition.y);
      }
    }
  });
});