define('ember-router-scroll/services/router-scroll', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var getWithDefault = _ember['default'].getWithDefault;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'scrollMap', {});
      set(this, 'key', null);
    },

    update: function update() {
      var scrollMap = get(this, 'scrollMap');
      var key = get(this, 'key');

      if (key) {
        set(scrollMap, key, { x: window.scrollX, y: window.scrollY });
      }
    },

    position: computed(function position() {
      var scrollMap = get(this, 'scrollMap');
      var stateUuid = get(window, 'history.state.uuid');

      set(this, 'key', stateUuid);
      var key = getWithDefault(this, 'key', '-1');

      return getWithDefault(scrollMap, key, { x: 0, y: 0 });
    }).volatile()
  });
});
/* eslint-disable */