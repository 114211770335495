define('ember-router-scroll/locations/router-scroll', ['exports', 'ember'], function (exports, _ember) {

  var uuid = function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0;
      var v = c === 'x' ? r : r & 3 | 8;
      return v.toString(16);
    });
  };

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var HistoryLocation = _ember['default'].HistoryLocation;
  exports['default'] = HistoryLocation.extend({
    pushState: function pushState(path) {
      var state = { path: path, uuid: uuid() };
      get(this, 'history').pushState(state, null, path);
      set(this, 'previousURL', this.getURL());
    },
    replaceState: function replaceState(path) {
      var state = { path: path, uuid: uuid() };
      get(this, 'history').replaceState(state, null, path);
      set(this, 'previousURL', this.getURL());
    }
  });
});
/* eslint-disable */